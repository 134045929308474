// Start Swiper
.swiper {
    padding: 20px !important;
    .content-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ".";
        width: 100%;
        justify-items: center;
        align-items: center;
        .content {
            padding: 28px 16px;
            .slide-header {
                display: flex;
                column-gap: 10px;
                justify-content: space-between;
                margin-bottom: 24px;
                .slide-info {
                    display: flex;
                    column-gap: 10px;
                    .name {
                        font-weight: bold;
                        font-size: 18px;
                        max-width: 112px;
                    }
                    .job {
                        font-size: 12px;
                    }
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
                .rating {
                    span {
                        color: #FFA033;
                        &.active {
                            &::before {
                                content: "\2605";
                                position: absolute;
                                opacity: 1
                            }
                        }
                    }
                }
            }
            .slide-desc {
                text-align: center;
                h4 {
                    font-weight: 600;
                    font-size: 18px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
    .swiper-slide {
        //margin: 0;
        width: 100%;
        opacity:.8;
        background:rgba(255,255,255,1);
        transition: all .5s ease-in-out;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        height: 306px;
        &.swiper-slide-active {
            background:rgba(255,255,255,1);
            opacity:1;
            //transform: scale(1.2);
        }
    }
    .swiper-nav-wrapper {
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: auto;
        padding-top:46px;
        .swiper-button-next,
        .swiper-button-prev {
            top: auto;
            left: auto;
            right: auto;
            position: relative !important;
            &:after {
                display: none;
            }
        }
        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
            background-image: url("/img/right-arrow.svg") !important;
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
            background-repeat: no-repeat;
            margin: 0;
            padding: 0;
        }

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
            background-image: url("/img/left-arrow.svg") !important;
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
            background-repeat: no-repeat;
            margin: 0;
            padding: 0;
        }
    }
}

.swiper-pagination {
    margin: 0;
    padding: 0;
    width: auto;
    position: relative !important;
    display: block;
    .swiper-pagination-bullets {
        margin: 0;
        .swiper-pagination-bullet {
            margin: 0 !important;
            background: $pink !important;
        }
    }
}
.swiper-pagination-bullet {
    background: $pink !important;
    transition: all .2s ease-in-out;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0;
    top: 0;
    width: auto !important;
    padding: 0 1.5em;
    column-gap: 15px;
}
.swiper-pagination-bullet-active {
    transform: scale(1.5);
}

@include media-breakpoint-up(md) {
    .swiper {
        padding: 70px 20px 20px 20px !important;
        .swiper-slide {
            &.swiper-slide-active {
                transform: scale(1.2);
            }
        }
        .swiper-nav-wrapper {
            padding-top: 70px;
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xxl) {

}
