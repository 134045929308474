.contact, .buy-form {
    a {
        color: $body-color;
        text-decoration: none;
        &.email {
            font-weight: bold;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    .alia-form {
        margin-top: 20px;
        input, textarea {
            font-weight: 300;
            font-size: 20px;
            border-radius: unset;
            margin-bottom: 24px;
            border: 1px solid #3F3F3F;
            &.is-invalid {
                //border: 1px solid #dc3545;
            }
        }
        input[type=submit] {
            border: none;
        }
        .submit-btn, button {
            font-weight: 600;
            font-size: 20px;
            background-color: $pink;
            border-radius: unset;
            color: white;
            border: none;
            width: 100%;
            padding: 8px;
            margin-top: 30px;
            &:hover {
                opacity: .9;
            }
        }
        .tos-checks {
            display: flex;
            flex-direction: column;
            .tos-item {
                font-size: 16px;
                display: flex;
                column-gap: 8px;
                label {
                    margin-top: 3px;
                }
                .check-link {
                    color: $pink;
                    text-decoration: underline;
                }
            }
        }
        .form-check-input:checked {
            background-color: $pink;
        }
    }
}

@include media-breakpoint-up(md) {
    .contact {
        .alia-form {
            .submit-btn, button {
                width: 192px;
            }
        }
    }
}
