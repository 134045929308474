#detalle-congreso {
    .download-program {
        display: flex;
        text-decoration: none;
        column-gap: 20px;
        width: fit-content;
        &:hover {
            text-decoration: underline;
            color: $pink;
        }
    }
    .conference-sm {
        .conference {
            width: 100%;
            max-width: none;
            .conference-date {
                width: 170px;
                .date {
                    width: 106px;
                }
            }
        }
        .download-program {
            margin: 0 auto 40px;
        }
    }
}

#detalle-congreso, #comprar-congreso {
    margin-top: 60px;
    form {
        .form-control {
            border-radius: 0;
        }
    }
}

#comprar-congreso {
    #company {
        display: none;
    }

    .buy-title-wrapper {
        margin-bottom: 24px;
    }
    .select-type-wrapper {
        display: flex;
        column-gap: 24px;
        margin-bottom: 32px;
        div {
            display: flex;
            align-items: center;
            column-gap: 8px;
            input {
                height: 18px;
                width: 18px;
            }
        }
    }
    .button {
        margin-top: 24px;
    }
}

.congress-resume-title {
    h2 {
        font-size: 3.25rem;
    }
    p {
        margin-top: 22px;
        margin-bottom: 32px;
    }
}

@include media-breakpoint-up(md) {
    #detalle-congreso, #comprar-congreso {
        margin-top: 65px;
    }
    #comprar-congreso {
        .buy-title-wrapper {
            margin-bottom: 31px;
        }
        .select-type-wrapper {
            column-gap: 42px;
        }
    }
    .congress-resume-title {
        h2 {
            font-size: 3.125rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    #detalle-congreso, #comprar-congreso {
        margin-top: 80px;
    }
}
