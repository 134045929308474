// Carousel
.carousel {
    .carousel-indicators {
        bottom: 24px;
        column-gap: 15px;

        .carousel-indicator {
            width: 17px;
            height: 17px;
            border-radius: 17px;
            border-bottom: 0;
            border-top: 0;

            &.active {
                background-color: $pink;
            }
        }
    }

    .carousel-img {
        /*height: 568px;
        width: auto;*/
    }

    .carousel-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white;
        justify-content: center;

        h2 {
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
        }
        h2.slide1 {
            width: 260px;
        }

        p {
            font-weight: 300;
            font-size: 24px;
            line-height: 25px;
        }
    }
    .full-screen {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

@include media-breakpoint-up(md) {
    // Carousel
    .carousel {
        .carousel-img {
            /*width: 100%;
            height: 100%;*/
        }

        .carousel-content {
            h2 {
                font-size: 48px;
                line-height: 70px;
            }

            p {
                font-size: 36px;
                line-height: 50px;
            }
        }
    }
}

@media (min-width: 575px) {
    .carousel {
        .carousel-content {
            h2.slide1 {
                width: 100%;
            }
        }
    }
}
