// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Partials
@import "navbar";
@import "carousel";
@import "conferences";
@import "congress_detail";
@import "holidays";
@import "services";
@import "testimonials";
@import "contact_form";
@import "providers";
@import "footer";

section {
    padding: 80px 0;
    &.external {
        margin-top: 10px;
    }
}

.line-separator {
    height: 1px;
    width: 100%;
    background-color: #DEDEDE;
}

.title {
    font-size: 3rem;
    margin-bottom: 24px;
}

.italic {
    font-style: italic;
}
.bold {
    font-weight: bold;
}

.pink {
    color: $pink;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.button {
    background-color: $pink;
    color: white;
    width: 100%;
    padding: 8px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    transition: all .3s;
    border: none;
    &:hover {
        color: white;
        opacity: 1;
        background-color: rgba(133, 96, 168);
    }
}

.form-field {
    position: relative;
    .input-label {
        position: absolute;
        top: -10px;
        left: 20px;
        font-size: 12px;
        padding: 0 8px;
        background-color: white;
        display: none;
        &.error {
            color: red;
        }
    }
}

input[type=radio] {
    accent-color: $pink;
}

#errors-wrapper {
    display: none;
}

.link {
    color: black;
    text-decoration: none;
    &:hover {
        color: black;
        text-decoration: underline;
    }
    &.pink {
        color: $pink;
        &:hover {
            color: $pink;
        }
    }
}
body {
    overflow: overlay;
    &::-webkit-scrollbar {
        cursor: pointer;
        height: 10px;
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: lightgray;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:active,
    &::-webkit-scrollbar-thumb:hover {
        background-color: $pink;
    }
}

@include media-breakpoint-up(md) {
    section {
        padding: 100px 0;
        &.external {
            margin-top: 40px;
        }
    }
    .title {
        font-size: 3.688rem;
    }
    .button {
        width: fit-content;
        padding: 8px 50px;
    }
}

@include media-breakpoint-up(lg) {
    section {
        &.external {
            margin-top: 60px;
        }
    }
}

@include media-breakpoint-up(xxl) {

}
