// Nav bar
.navbar {
    //padding-bottom: 0;

    &.light {
        background-color: white !important;

        .navbar-nav {
            .nav-link {
                color: $nav-dark-text;
            }
        }

        .burger-menu {
            label {
                & span {
                    background: black;
                }
            }
        }
    }

    &.dark {
        background-color: transparent !important;

        .navbar-nav {
            .nav-link {
                color: white !important;
            }
        }

        .burger-menu {
            label {
                & span {
                    background: white;
                }
            }
        }
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link,
    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: $pink
    }

    .navbar-toggler {
        border: none !important;
    }

    .navbar-nav {
        column-gap: 10px;

        .nav-item {
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background: $pink;
                transition: 0.4s
            }

            &:hover::after {
                width: 100%;
            }

            .nav-link {
                font-size: .9rem;
                padding: 3px 10px;
                transition: 0.2s;
            }
        }
    }

    .navbar-brand {
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    .navbar-mv {
        background-color: white;
        width: 100%;
        height: 100%;

        .navbar-nav-mv {
            list-style: none;
            margin: 0;
            display: flex;
            justify-content: center;
            padding: 20px;
            flex-direction: column;
            border-bottom: 4px solid $pink;
            align-items: center;

            .nav-item {
                .nav-link {
                    color: black;
                }
            }
        }
    }

    .burger-menu {
        label {
            display: flex;
            flex-direction: column;
            width: 18px;
            cursor: pointer;

            & span {
                background: white;
                border-radius: 10px;
                height: 2px;
                margin: 3px 0;
                transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

                &:nth-of-type(1) {
                    width: 80%;
                }

                &:nth-of-type(2) {
                    width: 100%;
                }

                &:nth-of-type(3) {
                    width: 65%;
                }
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked ~ span:nth-of-type(1) {
                transform-origin: top;
                transform: rotatez(45deg) translate(5px, 2px);
                width: 50%;
            }

            input[type="checkbox"]:checked ~ span:nth-of-type(2) {
                transform-origin: top;
                transform: rotatez(-45deg)
            }

            input[type="checkbox"]:checked ~ span:nth-of-type(3) {
                transform-origin: bottom;
                width: 50%;
                transform: translate(8px, -5px) rotatez(45deg);

            }
        }
    }
}

@media (min-width: 600px) {
    .navbar {
        padding-top: 24px;
    }
}

@include media-breakpoint-up(md) {
    // nav bar
    .navbar {
        .navbar-nav {
            //column-gap: 24px;
        }
    }
}

@include media-breakpoint-up(lg) {
    // Nav bar
    .navbar {
        .navbar-nav {
            column-gap: 24px;
            .nav-item {
                .nav-link {
                    font-size: $font-size-base;
                }
            }
        }

    }
}

@include media-breakpoint-up(xxl) {

}
