#congresos {
    .conferences-right-col {
        align-self: end;
    }
    .row:first-child {
        margin-bottom: 36px;
    }
}
.conference {
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: 404px;
    &.conference-1 {
        margin-bottom: 32px;
        background-color: rgba(206, 52, 135, 0.67);
        .conference-date {
            .date {
                background-color: rgba(206, 52, 135, 0.67);
            }
        }
        .conference-content {
            .button {
                &:hover {
                    background-color: rgba(133, 96, 168, 0.67);
                }
            }
        }
    }
    &.conference-2 {
        background-color: rgba(133, 96, 168, 0.67);
        .conference-date {
            .date {
                background-color: rgba(133, 96, 168, 0.67);
            }
        }
        .conference-content {
            .button {
                &:hover {
                    background-color: rgba(206, 52, 135, 0.67);
                }
            }
        }

    }
    .conference-content {
        padding: 16px 24px 24px;
        font-weight: bold;
        font-size: 22px;
        .title {
            font-size: 17px;
            font-weight: normal;
            margin-bottom: 8px;
        }
        .time-0 {
            font-weight: bold;
            font-size: 16px;
        }
        .time-1 {
            font-weight: normal;
            font-size: 16px;
            text-transform: lowercase;
        }
        .button {
            text-decoration: none;
            background-color: white;
            color: black;
            width: 150px;
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            padding: 4px 0;
            margin-top: 30px;
            transition: background-color .3s;
            &:hover {
                color: white;
            }
        }
    }
    .conference-date {
        overflow: hidden;
        width: 116px;
        text-transform: uppercase;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
        .date {
            width: 100%;
            height: 106px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .date-0 {
                font-weight: bold;
                font-size: 36px;
                line-height: normal;
            }
            .date-1 {
                font-weight: normal;
                font-size: 24px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .conference {
        .conference-date {
            width: 169px;
            .date {
                width: 106px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {

}
