#vacaciones {
    .holidays-section {
        margin-bottom: 32px;
    }

    .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 381px;
        margin-bottom: 32px;

        &.img-1 {
            background-image: url("/img/holidays-1.png");
        }

        &.img-2 {
            background-position: left;
            background-image: url("/img/holidays-2.png");
        }

        &.img-3 {
            background-image: url("/img/holidays-3.png");
        }

        &.img-4 {
            background-image: url("/img/holidays-4.png");
        }

        &.img-5 {
            background-image: url("/img/holidays-5.png");
        }
    }

    .icon {
        margin-bottom: 24px;
        width: 56px;
    }

    .holidays-p-title {
        font-weight: bold;
    }

    .first-row {
        margin-bottom: 124px;
    }

    .space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@include media-breakpoint-up(md) {
    #holidays {
        .image {
            margin-bottom: 0;
            &.img-1, &.img-3 {
                margin-bottom: 32px;
            }
            &.img-2 {
                background-position: center;
            }
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xxl) {

}
