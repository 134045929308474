footer {
    background-color: black;
    color: white;
    padding: 70px 0 40px;
    .footer-links {
        align-items: center;
        display: flex;
        flex-direction: column;
        row-gap: 44px;
        .logo {
            width: fit-content;
        }
        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            column-gap: 4px;
            list-style: none;
            font-size: 20px;
            margin: 0 0 24px 0;
            padding: 0;
            align-items: center;
            row-gap: 24px;

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
    p {
        text-align: center;
    }
}

@media (min-width: 767px) {
    footer {
        .footer-links {
            .logo {
                align-self: start;
            }
            ul {
                flex-direction: row;
                li:first-child:before {
                    content: "";
                    margin-right: 0;
                }

                li:before {
                    content: "|";
                    font-size: 18px;
                    margin-right: 4px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    footer {
        .footer-links {
            flex-direction: row;
            ul {
                margin: 0 auto;
            }
        }
        p {
            margin-top: 50px;
        }
    }
}
