#services {
    h4 {
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 44px;
    }
    .services-wrapper {
        display: grid;
        font-size: 23px;
        line-height: 46px;
    }
}

@include media-breakpoint-up(sm) {
    #services {
        .services-wrapper {
            grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        }
    }
}

@include media-breakpoint-up(md) {
    #services {
        .services-wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}

@include media-breakpoint-up(xxl) {

}
