.providers-wrapper {
    display: none;
    column-gap: 48px;
    row-gap: 48px;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    margin-top: 74px;
}

@include media-breakpoint-up(md) {
    .providers-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@include media-breakpoint-up(lg) {
    .providers-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
