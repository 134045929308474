// Body
$body-bg: #ffffff;
$body-color: #000000;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 1.25rem;
$line-height-base: 1.6;

$grid-breakpoints: (
        xs: 0,
        sm: 321px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
);

$container-max-widths: (
        sm: 672px,
        md: 860px,
        lg: 960px,
        xl: 1140px,
        xxl: 1260px
);

// Colors
$pink: #D34A94;
$nav-dark-text: #060606;
